import React, {useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "../../util/type";
import {getConnected} from "../../util/helper";
import OrderFormItemOrders, {IOrder} from "./order-form-item-orders";
import {uploadFileWithOutUuid} from "../../services/awsS3Services";
import {post} from "../../services/BaseService";
import {ordersUrls} from "../../util/api_constant";
import {navigate} from "gatsby";
import OrderFormItemWarehouses from "./order-form-item-warehouses";

declare let bootstrap: any;

const OrderForm = () => {
    const modalRef = useRef<any>();
    const {register, handleSubmit, unregister, setValue, formState: {errors}} = useForm<any>({
        mode: 'all',
        // resolver: yupResolver(orderSchema)
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorRes, setErrorRes] = useState<ErrorMessage>();
    const [orders, setOrders] = useState<IOrder[]>([{num: 1, name: 'order1', type:'AERIAL', currency:'EURO', group: 'TRUE', invoice: undefined, uploading: false, progress:1, open: true, removable:false, trackingNumbers:[{num: 1, name: 'order1_track1'}]}]);
    const [warehouse, setWarehouse] = useState<number>(1);
    const [group, setGroup] = useState<string>("FALSE");
    const [type, setType] = useState<string>("AERIAL");

    const onSubmit = (data: any) => {

        let body: any = {
            offer: 'flex', warehouse: {id: warehouse},
            destinationCode: data.destinationCode,
            notes: data.notes,
            state: 'NEW',
            source: 'CLIENT',
            isGrouped: group,
            type: type,
            orderDate: new Date(),
            customer: getConnected()
        }
        let products: any[] = [];
        orders.forEach((order, index) => {

            const trackingNumbers: any[] = order.trackingNumbers.filter((t: any) => data.hasOwnProperty(t.name) && data[t.name]);
            if (trackingNumbers.length > 0){
                trackingNumbers.forEach((item: any) => {
                    let trackingNumber : any = {};
                    trackingNumber[`${data[item.name]}`]= {trackingNumber: data[item.name], state: 'NOT_RECEIVED', productCount:1}
                    products.push({
                        trackingNumber: trackingNumber,
                        state: 'NOT_RECEIVED',
                        name: 'UNKNOWN',
                        currency: order.currency,
                        website: data[`${order.name}_website`],
                        price: +data[`${order.name}_price`],
                        category: data[`${order.name}_category`],
                        index: index
                    })
                });
            }else {
                products.push({
                    trackingNumber: {},
                    state: 'NOT_RECEIVED',
                    name: 'UNKNOWN',
                    currency: order.currency,
                    website: data[`${order.name}_website`],
                    price: +data[`${order.name}_price`],
                    category: data[`${order.name}_category`],
                    index: index
                })
            }

        })

        setIsSubmitting(true);
        setErrorRes(undefined);
        const invoices = orders.map((o, index) => ({invoice: o.invoice, order: o.name, index: index}));

        if (invoices.length){
            invoices.forEach((item) => {
                const splits = item.invoice.name.split('.');
                setOrders((prevState) => {
                    let items = prevState.slice();
                    items[item.index].uploading = true;
                    items[item.index].open = false;
                    return items;
                })
                uploadFileWithOutUuid('invoices', `order_invoice_${new Date().getTime()}.${splits[splits.length - 1]}`, item.invoice)
                           .on('httpUploadProgress', (evt) => {
                               setOrders((prevState) => {
                                   let items = prevState.slice();
                                   items[item.index].progress = Math.round((evt.loaded / evt.total) * 100);
                                   return items;
                               })
                           })
                           .send((error: any, data: any) => {
                               if (data){
                                   // body['invoice'] = data.Location;
                                   if (products.length){
                                       products.forEach(product => {
                                           if (product.index === item.index){
                                               product.name = data.Location;
                                           }
                                       })
                                       /*const productIndex = products.findIndex(p => p.index === item.index);
                                       if (productIndex >= 0){
                                           products[productIndex].name = data.Location;
                                       }*/
                                   }
                                   //Is all invoice uploaded ?
                                   if (!products.some(p => p.name === 'UNKNOWN')){
                                       body['products'] = products;
                                       body['price'] = products.map(p => p.price).reduce((p, n) => p + n);
                                       post(ordersUrls.base, body).then(() => {
                                           if (bootstrap){
                                               const modal =  new bootstrap.Modal('#confirmModal', {
                                                   keyboard: false,
                                                   backdrop:'static'
                                               });
                                               modalRef.current = modal;
                                               modal.show();
                                           }
                                           setIsSubmitting(false);
                                           setErrorRes(undefined);
                                       }).catch((e: ErrorMessage) => {
                                           setErrorRes(e);
                                           console.log("err" ,e);
                                           setIsSubmitting(false);
                                           if (typeof window !== undefined){
                                               window.scroll(0, 0);
                                           }
                                       });
                                   }

                                   setOrders((prevState) => {
                                       let items = prevState.slice();
                                       items[item.index].progress = 1;
                                       items[item.index].uploading = false;
                                       return items;
                                   })

                               }
                               if (error){
                                   setOrders((prevState) => {
                                       let items = prevState.slice();
                                       items[item.index].progress = 1;
                                       items[item.index].uploading = false;
                                       return items;
                                   })
                                   setIsSubmitting(false);
                                   // setProgress(1);
                                   // setErrorRes(`${error}`);
                               }
                           })
            })
        }


    };
    const confirm = () => {
        if (modalRef.current){
            modalRef.current.hide();
        }
        if (typeof window !== "undefined"){
            navigate('/app/profile');
        }
    }
    const cancel = () => {
        if (modalRef.current){
            modalRef.current.hide();
        }
        if (typeof window !== "undefined"){
            window.location.reload();
        }
    }

    return <div className="card card-lg">
        <div className="card-body">
            <h4>Afrisends | Création de commande</h4>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    {errorRes && <div className="alert alert-danger" role="alert"><strong>{errorRes.message}</strong></div>}
                </div>

                <p>Vers quel entrepôt expédiez vous vos colis?</p>
                <OrderFormItemWarehouses isSubmitting={isSubmitting} onWarehouseChange={value => {
                    setWarehouse(value);
                    setValue('destinationCode', 'MALI')
                    if (value === 2 || value === 3)
                        setType("AERIAL");
                }} />

                <div className="alert alert-soft-success" role="alert">
                    <p>Offre Flex - Do it your way</p>
                     <span>Nous supposons que vous avez déjà acheté vos articles aux sites marchands de votre choix et vous voulez les livrer à l’adresse Afrisends.</span> <a target={'_blank'} href="/">En savoir plus sur nos offres.</a>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center"><hr className="text-primary"/></div>
                    <div className="col-sm-4 form-floating mb-3">
                        <select
                            // name="destinationCode"
                            {...register(`destinationCode`,{required: true})}
                            id={`destinationCode`}
                            className={`form-select form-select-lg ${errors[`destinationCode`] ? 'is-invalid' : ''}`}
                            aria-label="Pays de livraison"
                            defaultValue={'MALI'}
                            disabled={isSubmitting}
                        >
                            <option  value={"MALI"}>Mali</option>
                            <option disabled={(warehouse !== 1)} value={"SENEGAL"}>Sénégal</option>
                            <option disabled={(warehouse !== 1)}  value={"Côte d'Ivoire"}>Côte d'Ivoire</option>
                            <option disabled={(warehouse !== 1)} value={"BENIN"}>Benin</option>
                            <option disabled={(warehouse !== 1)} value={"TOGO"}>Togo</option>
                            <option disabled={(warehouse !== 1)} value={"GABON"}>Gabon</option>
                            <option disabled={(warehouse !== 1)} value={"CONGO BRAZAVILLE"}>Congo</option>

                        </select>
                        <label
                            className="form-label"
                            htmlFor={`destinationCode`}
                        >
                            Pays de destination des commandes <span className="form-label-secondary text-danger">*</span>
                        </label>
                    </div>
                    <div className="col-sm-3 mb-3">
                        <p className="form-label">Votre choix de transport</p>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name={`type`} id="AERIAL" onChange={() => setType('AERIAL')} checked={type === "AERIAL"}
                                   disabled={isSubmitting}/>
                            <label className="form-check-label" htmlFor="AERIAL">AERIEN</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio"  name={`type`} id="MARTIM" onChange={() => setType('MARTIM')} checked={type === "MARTIM"}
                                   disabled={isSubmitting || (warehouse === 2) || (warehouse === 3)}/>
                            <label className="form-check-label" htmlFor="MARTIM">MARITIME</label>
                        </div>
                    </div>
                    <div className="col-sm-5 mb-1">
                        <p className="form-label">Faut-il grouper les commandes ?</p>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name={`group`} id="group_yes" onChange={() => setGroup( 'TRUE')} checked={group === "TRUE"}
                                   disabled={isSubmitting}/>
                            <label className="form-check-label" htmlFor="group_yes">Oui</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name={`group`} id="group_no" onChange={() => setGroup('FALSE')} checked={group === "FALSE"}
                                   disabled={isSubmitting}/>
                            <label className="form-check-label" htmlFor="group_no">Non expediez dès que possible</label>
                        </div>
                    </div>

                    <div className="col-sm-12"><hr className="text-primary"/></div>
                </div>
                <OrderFormItemOrders items={orders} onChange={setOrders} register={register} unregister={unregister} errors={errors} isSubmitting={isSubmitting}/>

                <div className="col-sm-12 form-floating mb-3 mb-sm-3">
                    <textarea className="form-control" {...register('notes', {})} id="notes" inputMode={"text"}
                              style={{minHeight: '100px'}}/>
                    <label htmlFor="notes" className="form-label">Laissez-nous une note.</label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" {...register('terms', {required: "Lisez et acceptez les Conditions Générale de Vente"})} type="checkbox" id="terms" />
                        <label className="form-check-label" htmlFor="terms">
                            Je déclare avoir lu et accepter les <a target="_blank" href="/terms">Conditions Générale de Vente</a>
                        </label>
                    {errors.terms && <div className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.terms.message}</div>}

                </div>
                <div className="d-grid mb-2">
                    <button type="submit" className="btn btn-primary btn-lg" disabled={isSubmitting}>
                        {isSubmitting?<div><span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true" />&nbsp;&nbsp;Création de la commande</div>:<span>Créer une commande</span>}
                    </button>
                </div>
            </form>

        </div>
        <div className="modal fade" id="confirmModal"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Félicitations ! </h5>
                        <button type="button" className="btn-close"  onClick={() => {cancel()}}></button>
                    </div>
                    <div className="modal-body">
                        <span>{`Nous avons bien réçu ${orders.length === 1? 'votre commande': 'vos ('+orders.length+') commandes'}. Vous pouvez gérer vos commandes dans votre profile.`}</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary" style={{}} onClick={() => {cancel()}}>Rester ici</button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => {confirm()}}>Aller à mon profile</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default OrderForm;