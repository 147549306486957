import React, {useEffect, useRef, useState} from "react";
import OrderFormItemCurrency from "./order-form-item-currency";
import OrderFormItemTrackingNumbers from "./order-form-item-tracking-numbers";
export interface IOrder { num: number, name: string, type: string, group: string, currency: string, invoice: any, uploading: boolean, progress: number, open:boolean, removable:boolean, trackingNumbers: { num: number, name: string }[]}
const OrderFormItemOrders = ({register, isSubmitting, errors, unregister, onChange, items}:{items: any[], onChange: (items: any[]) => void, register: (index: string, option?:any) => any, unregister: (index: string) => any, errors: any, isSubmitting: boolean}) => {
    const [orders, setOrders] = useState<IOrder[]>(items);

    useEffect(() => {
        onChange(orders);
    }, [orders])
    const addOrder = () => {
        setOrders((prevState) => {
            const items = prevState.slice();
            const num = items[items.length -1].num + 1;
            items.forEach(i => {i.open = false})
            items.push({num: num, name: `order${num}`, type:'AERIAL', currency:'EURO', group: 'TRUE', invoice: undefined, uploading: false, progress:1, open: true, removable:true, trackingNumbers: [{num: 1, name: `order${num}_track1`}]})
            return items;
        })
    }

    const collapseOrder = (num: number) => {
        if (!isSubmitting)
        setOrders((prevState) => {
            const items = prevState.slice();
            items.forEach(i => {
                if (i.num === num)
                    i.open = !i.open;
                else
                    i.open = false;
            })
            return items;
        })
    }

    const removeOrder = (num:number, index:number) => {
        unregister(`${orders[index].name}_category`)
        unregister(`${orders[index].name}_website`)
        unregister(`${orders[index].name}_price`)
        unregister(`${orders[index].name}_invoice`)
        orders[index].trackingNumbers.forEach(t => {
            unregister(`${t.name}`)
        })

        setOrders((prevState) => {
            const items = prevState.slice();
            return items.filter(item => item.num !== num);
        })
    }
    const setCurrency = (index: number, value: string) => {
        setOrders((prevState) => {
            const items = prevState.slice();
            items[index].currency = value;
            return items;
        })
    }
    const setInvoice = (index: number, value: any) => {
        setOrders((prevState) => {
            const items = prevState.slice();
            items[index].invoice = value;
            return items;
        })
    }
    const setOrderTrackingNumbers = (index: number, value: any[]) => {
        setOrders((prevState) => {
            const items = prevState.slice();
            items[index].trackingNumbers = value;
            return items;
        })
    }

    return <div className="accordion accordion-flush" id="accordionExample">
        {
            orders.map((o, index) => (
                <div className="accordion-item" key={o.name}>
                    <div className="accordion-header" id={`collapse${o.name}`}>
                        <button onClick={() => {collapseOrder(o.num)}} className={`accordion-button ${!o.open?'collapsed':''}`} type="button" >
                            {o.uploading?
                                <div className="progress w-100" style={{height: '20px'}}>
                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${o.progress}%`}} aria-valuenow={o.progress} aria-valuemin={0}
                                    aria-valuemax={100}><span style={{fontSize: '0.7rem'}}>Commande #{(index + 1)} : Envoi de la facture à {o.progress}%</span>
                                    </div>
                                </div>:
                                <span style={{marginRight: '10px'}}>Commande #{(index + 1)}</span>}
                        </button>
                    </div>
                    <div id={`collapse${o.name}`} className={`accordion-collapse ${!o.open?'collapse':''}`} data-bs-parent="#accordionExample">
                        <div className="p-2">
                            <div className="row">
                                <div className="col-sm-4 form-floating mb-3 mb-sm-0">
                                    <input
                                        type="text"
                                        className={`form-control ${errors[`${o.name}_website`] ? 'is-invalid' : ''}`}
                                        // name="height"
                                        id={`${o.name}_website`}
                                        {...register(`${o.name}_website`, {required: true})}
                                        placeholder={`Site web marchand ${errors[`${o.name}_website`] ? 'est obligatoire' : ''}`}
                                        aria-label="Site web marchand"
                                        disabled={isSubmitting}
                                    />
                                    <label
                                        className="form-label"
                                        htmlFor={`${o.name}_website`}
                                    >
                                        Site web marchand
                                        <span className="form-label-secondary text-danger">*</span>
                                    </label>
                                </div>
                                <div className="col-sm-4 form-floating mb-3 mb-sm-0">
                                    <input
                                        type="text"
                                        className={`form-control ${errors[`${o.name}_category`] ? 'is-invalid' : ''}`}
                                        // name="height"
                                        id={`${o.name}_category`}
                                        {...register(`${o.name}_category`,{required: false})}
                                        placeholder={`Nature des articles de la commande.`}
                                        aria-label="Nature des articles de la commande."
                                        disabled={isSubmitting}
                                    />
                                    <label
                                        className="form-label"
                                        htmlFor={`${o.name}_category`}
                                    >
                                        Nature des colis
                                        <span className="form-label-secondary"
                                              style={{fontSize: 10}}> Ex:Habits, ... (Optionnel)</span>
                                    </label>
                                </div>
                                <div className="col-sm-4 form-floating mb-1">
                                    <input
                                        type="text"
                                        className={`form-control ${errors[`${o.name}_price`] ? 'is-invalid' : ''}`}
                                        // name="height"
                                        id={`${o.name}_price`}
                                        {...register(`${o.name}_price`,{required: true})}
                                        placeholder={`Montant de la facture ${errors[`${o.name}_price`]? 'est obligatoire' : ''}`}
                                        aria-label="Montant de la facture"
                                        disabled={isSubmitting}
                                    />
                                    <label
                                        className="form-label"
                                        htmlFor={`${o.name}_price`}
                                    >
                                        Montant de la facture
                                        <span className="form-label-secondary text-danger">*</span>
                                    </label>
                                </div>

                                <div className="col-sm-8 form-floating mb-3 mt-2">
                                    <input {...register(`${o.name}_invoice`,{required: true})} type="file"
                                           className={`form-control ${errors[`${o.name}_invoice`] ? 'is-invalid' : ''}`} id={`formFile_${o.name}`} max={1}
                                           accept={'.pdf,.jpeg,.png,.jpg,.webp'} onChange={event => {
                                        if (event && event.target && event.target.files)
                                            setInvoice(index, event.target.files[0])
                                    }} disabled={isSubmitting}/>
                                    <label htmlFor={`formFile_${o.name}`} className="form-label">Selectionnez la Facture <span className="form-label-secondary text-danger">*</span></label>
                                </div>
                                <div className="col-sm-4 mb-3 mt-2">
                                    <p className="form-label">Devise du montant de la facture</p>
                                    <OrderFormItemCurrency name={o.name} onSelect={item => setCurrency(index, item)} selected={o.currency} isSubmitted={isSubmitting}/>
                                </div>
                            </div>
                            <OrderFormItemTrackingNumbers order={o.name} onChange={items => {
                                setOrderTrackingNumbers(index, items);
                            }} unregister={unregister} isSubmitted={isSubmitting} errors={errors} register={register}/>
                        </div>
                        {o.removable && <div className="text-center mb-3">
                            <button onClick={() => removeOrder(o.num, index)} className="btn btn btn-soft-danger" type="button"
                                    style={{fontSize: 12}}><i className="bi-cart-x"/>&nbsp;&nbsp;Enlèver cette commande
                            </button>
                        </div>}
                    </div>
                    <div style={{paddingLeft: '30%', paddingRight:'30%'}}><hr className="text-success text-center mt-0 mb-0"/></div>
                </div>
            ))
        }
        <div className="text-center mt-3 mb-3"><button disabled={isSubmitting} onClick={addOrder} className="btn btn btn-soft-success" type="button" style={{fontSize: 12}}><i className="bi-cart-plus"/>&nbsp;&nbsp;Ajouter une autre commande</button></div>
    </div>
}

export default OrderFormItemOrders;