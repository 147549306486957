import React from "react";
import Layout from "../../components/layout/Layout";
import PlaceOrderContent from "../../components/place-order/place-order-content";
const PlaceOrder = () => {

    return (
    <Layout title={'Confiez-nous vos colis à livrer partout en afrique depuis l\'Europe et les USA'}>
        <PlaceOrderContent/>
    </Layout>
    )
}
export default PlaceOrder;