import React from "react";
import OrderForm from "./order-form";
import {useMediaQuery} from "../../util/media-query";

const PlaceOrderContent = () => {
    const isMobile = useMediaQuery('(max-width: 850px)')
  return <>
      <div className="content-space-t-3 content-space-t-lg-4 content-space-b-2 content-space-b-lg-3 overflow-hidden">
          <div className={`${isMobile?'':'container'}`}>
              <OrderForm/>
          </div>
      </div>
  </>
}
export default PlaceOrderContent;