import React from "react";

const OrderFormItemCurrency = ({onSelect, selected, isSubmitted, name}:{ onSelect: (item: string) => void, selected: string, isSubmitted: boolean, name: string}) => {
  return <>
      <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name={`${name}_currency`} id="inlineRadio2"
                 value="option2" checked={selected === "USD"}  onChange={() => {onSelect("USD")}} disabled={isSubmitted}/>
          <label className="form-check-label" htmlFor="inlineRadio2">$ Dollar USA</label>
      </div>
      <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name={`${name}_currency`} id="inlineRadio3"
                 value="option3" checked={selected === "EURO"}  onChange={() => {onSelect("EURO")}} disabled={isSubmitted}/>
          <label className="form-check-label" htmlFor="inlineRadio3">€ Euro</label>
      </div>
      <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name={`${name}_currency`} id="inlineRadio1"
                 value="option1" checked={selected === "XOF"}  onChange={() => {onSelect("XOF")}} disabled={isSubmitted}/>
          <label className="form-check-label" htmlFor="inlineRadio1">F CFA</label>
      </div>
  </>
}
export default OrderFormItemCurrency;