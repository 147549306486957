import React, {useState} from "react";


const OrderFormItemTrackingNumbers = ({register, isSubmitted, errors, unregister, onChange, order}:{order: string, onChange: (items: any[]) => void, register: (index: string) => any, unregister: (index: string) => any, errors: any, isSubmitted: boolean}) => {
   
    const [trackingNumbers, setTrackingNumbers] = useState<{ num: number, name: string}[]>([{num: 1, name: `${order}_track1`}]);

    const isLastIndex = (item:{num: number, name: string}) => {
        return trackingNumbers.findIndex(t => t.num === item.num) === (trackingNumbers.length - 1);
    }

    const addTrackingNumber = () => {
        setTrackingNumbers((prevState) => {
            const items = prevState.slice();
            const num = items[items.length -1].num + 1;
            items.push({num: num, name: `${order}_track${num}`})
            onChange(items);
            return items;
        })
    }

    const removeTrackingNumber = (num:number) => {
        setTrackingNumbers((prevState) => {
            const items = prevState.slice();
            onChange(items);
            return items.filter(item => item.num !== num);
        })
    }
    return <div className="card border-success mb-3">
        <div className="p-3">
            <div className="alert alert-soft-secondary " role="alert">
                Renseignez tous les numéros de suivi des colis de la commande. Si vous n'avez pas encore reçu les numéros de suivi de vos colis, ce n'est pas grave. Vous pouvez toujours revenir sur votre commande pour les ajouter au fur et à mesure que vous les recevez.
            </div>
        </div>
        
        {
            trackingNumbers.map((t, index )=> (
                <div className="input-group mb-3" key={`track_${t.num}_${order}`} style={{paddingLeft: '10px'}}>
                    <div className="form-floating " style={{width:'75%'}}>
                        <input
                            type="text"
                            className={`form-control ${errors[`${t.name}`] ? 'is-invalid' : ''}`}
                            id={`trackingNumber${t.num}_${order}`}
                            {...register(`${t.name}`)}
                            required={false}
                            aria-describedby="add-button"
                            disabled={isSubmitted}
                        />
                        <label className="form-label" htmlFor={`trackingNumber${t.num}_${order}`}>{`${(index + 1) === 1?'1er':(index + 1) + 'ème'} Numéro de suivi`}</label>
                    </div>
                    {!isLastIndex(t) ?<button onClick={()=> {
                            removeTrackingNumber(t.num);
                            unregister(t.name);
                        }} disabled={trackingNumbers.length === 1} className="btn btn btn-soft-danger text-center"
                                              type="button" id="add-button"
                                              style={{height: '57px', width: '10%'}}><i className="bi-dash-circle text-center"/></button> :
                        <button onClick={addTrackingNumber} className="btn btn btn-soft-success text-center" type="button" id="add-button" style={{height:'57px', width:'10%'}}><i className="bi-plus-circle"/></button>}
                </div>
            ))
        }
    </div>;
}

export default OrderFormItemTrackingNumbers;