import React, {useEffect, useState} from "react";

const OrderFormItemWarehouses = ({isSubmitting, onWarehouseChange}:{isSubmitting: boolean, onWarehouseChange: (value: number) => void}) => {
    const [warehouse, setWarehouse] = useState<number>(1);

    useEffect(() => {onWarehouseChange(warehouse)}, [warehouse])
    return <>
      <div className="row mb-4 text-center">
          <div className="col-md-4 col-lg-4 col-sm-6 form-check right-container mb-sm-5 mb-md-2">
              <input type="radio" className="btn-check" name="options-outlined" id="address-fr"
                     autoComplete="off" onChange={() => setWarehouse(1)} checked={warehouse === 1}
                     disabled={isSubmitting}/>
              <label className="btn btn-outline-primary" htmlFor="address-fr">
                  <div className="card-body">
                      <h4 className="card-title">Entrepôt Paris</h4>
                      <div>
                          <div style={{marginBottom: 8, fontSize: 12}}><i className="bi-geo-alt"/>&nbsp;&nbsp;93290,
                              TREMBLAY EN FRANCE
                          </div>
                          <div style={{fontSize: 10}}><i className="bi-phone"/>&nbsp;&nbsp;+33 7 86 10 93 06</div>
                      </div>
                  </div>
              </label>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-6 form-check left-container">
              <input type="radio" className="btn-check" name="options-outlined" id="address-usa"
                     autoComplete="off" onChange={() => {
                  setWarehouse(2);
              }} checked={warehouse === 2}
                     disabled={isSubmitting}/>
              <label className="btn btn-outline-primary" htmlFor="address-usa">
                  <div className="card-body">
                      <h4 className="card-title">Entrepôt USA</h4>
                      <div>
                          <div style={{marginBottom: 8, fontSize: 12}}><i className="bi-geo-alt"/>&nbsp;&nbsp;10026, 164
                              West 116th Street, NYC
                          </div>
                          <div style={{fontSize: 10}}><i className="bi-phone"/>&nbsp;&nbsp;001-212-666-7665</div>
                      </div>
                  </div>
              </label>
          </div>
          {/*<div className="col-md-4 col-lg-3 col-sm-6 form-check left-container">
              <input type="radio" className="btn-check" name="options-outlined" id="address-chine"
                     autoComplete="off" onChange={() => {
                  setWarehouse(3);
              }} checked={warehouse === 3}
                     disabled={isSubmitting}/>
              <label className="btn btn-outline-primary" htmlFor="address-chine">
                  <div className="card-body">
                      <h4 className="card-title">Entrepôt CHINE</h4>
                      <div>
                          <div style={{marginBottom: 8, fontSize: 9}}><i className="bi-geo-alt"/>&nbsp;&nbsp;5113 1st floor YUEYANG Trading Mall (B)
                              Lujing Road Yuexiu District, Guangzhou
                          </div>
                          <div style={{fontSize: 10}}><i className="bi-phone"/>&nbsp;&nbsp;+8613054444732</div>
                      </div>
                  </div>
              </label>
          </div>*/}
          <div className="col-md-4 col-lg-4 col-sm-6 form-check left-container">
              <input type="radio" className="btn-check" name="options-outlined" id="address-turk"
                     autoComplete="off" onChange={() => {
                  setWarehouse(4);
              }} checked={warehouse === 4}
                     disabled={isSubmitting}/>
              <label className="btn btn-outline-primary" htmlFor="address-turk">
                  <div className="card-body">
                      <h4 className="card-title">Entrepôt TURQUIE</h4>
                      <div>
                          <div style={{marginBottom: 8, fontSize: 10}}><i className="bi-geo-alt"/>&nbsp;&nbsp;Nişanca Mah. Katip Sinan Mektebi Sok. Tekçe No: 7/A laleli, İstanbul
                          </div>
                          <div style={{fontSize: 10}}><i className="bi-phone"/>&nbsp;&nbsp;00905442591514</div>
                      </div>
                  </div>
              </label>
          </div>
      </div>
  </>
}

export default OrderFormItemWarehouses;